
// var element = $('.scroll-pane').jScrollPane({
//   contentWidth: 772
// });

// var api = element.data('jsp');



var fooot = document.getElementById('footer');

// We create a manager object, which is the same as Hammer(), but without the presetted recognizers.
// var mc = new Hammer.Manager(fooot);

// // Tap recognizer with minimal 2 taps
// mc.add(new Hammer.Tap({
//     event: 'doubletap',
//     taps: 2
// }));
// // Single tap recognizer
// mc.add(new Hammer.Tap({
//     event: 'singletap'
// }));

// // we want to recognize this simulatenous, so a quadrupletap will be detected even while a tap has been recognized.
// mc.get('doubletap').recognizeWith('singletap');
// // we only want to trigger a tap, when we don't have detected a doubletap
// mc.get('singletap').requireFailure('doubletap');


// mc.on("singletap doubletap", function(ev) {
//     fooot.textContent += ev.type + "hello";
// });


var addSlide = document.querySelectorAll('.accordionBox .accocontent');
var addPdf = document.querySelectorAll('.accordionBox .clickpdf');
var addTex = document.querySelectorAll('.accordionBox .clicktex');
var mini = document.getElementById('miniWaren');
var waren = document.querySelector('.iso_colection_min');

for (var i = 0; i < addSlide.length; i++) {
    var num = i+1;
    var cssnum = 'slide0' + num;
    addSlide[i].setAttribute('id', cssnum);
    // console.log('Slider Nummer: ' + cssnum );
}

for (var i = 0; i < addPdf.length; i++) {
    var num = i+1;
    var cssnum = '0' + num;
    addPdf[i].setAttribute('id', 'lide' + cssnum);
    // console.log('PDF Nummer: ' + cssnum );
}

for (var i = 0; i < addTex.length; i++) {
    var num = i+1;
    var cssnum = '0' + num;
    addTex[i].setAttribute('id', 'lide' + cssnum);
    // console.log('Text Nummer: ' + cssnum );
}


function goMentales() {
  window.location.href = 'http://www.mentale-geburtsvorbereitung.ch/warenkorb.html';
}

function goVitales() {
  window.location.href = 'https://www.vitalis-shop.ch/warenkorb.html';
}

// function fadeWarenUp() {
//   slideUp();
// };

// var slideUp = function(addClick) {
//     addCli.slideUp();
// };

// var slideDown = function(addClick) {
//     addCli.slideDown();
// };

document.addEventListener('DOMContentLoaded', function() {
    var addClick = document.querySelectorAll('.accordionBox .aclick');
    var addPdf = document.querySelectorAll('.accordionBox .clickpdf');
    var addTex = document.querySelectorAll('.accordionBox .clicktex');
    var addSli = document.querySelectorAll('.accordionBox .accocontent');
    var ispdf = document.querySelector('.accordionBox .ispdf');
    var istex = document.querySelector('.accordionBox .istex');
    var isact = document.querySelector('.accordionBox .isact');
    var mini = document.getElementById('miniWaren');
    var warenX = document.querySelector('.warenX');
    var warenMent = document.querySelector('.warenMent');
    var warenVital = document.querySelector('.warenVital');
    var waren = document.querySelector('.iso_colection_min');

    if ( warenX.classList.contains('warenMent') ) {
    mini.addEventListener('click', function() {
        goMentales();
    })
  }
  else if ( warenX.classList.contains('warenVital')) {
      mini.addEventListener('click', function() {
          goVitales();
      })
    }

    for (var i = 0; i < addClick.length; i++) {
      addClick[i].addEventListener('click', function(e) {
        var thisID = e.target.getAttribute('id');
        var sliID = '#s' + thisID;
        var classL = e.target.classList;
        console.log('MySlideID: ' + sliID);

        for (var i = 0; i < addSli.length; i++) {

            var addSl = addSli[i];

          if ( addSl.classList.contains('ispdf') ) {
            addSl.classList.remove('ispdf');
            addSl.classList.remove('isact');
          } else if ( addSl.classList.contains('istex') ) {
            addSl.classList.remove('istex');
            addSl.classList.remove('isact');
          }

        }

        if ( classL.contains('clickpdf') ) {
          $(sliID).addClass("ispdf");
          $(sliID).addClass("isact");
        } else {
          $(sliID).addClass("istex");
          $(sliID).addClass("isact");
        }

    })
  };
});
